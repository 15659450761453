/*----------------------------------
- DEPENDANCES
----------------------------------*/

// Npm
import React, { JSX } from 'react';

// Core
import useContext from '@/client/context'; 

// Core components
import { Link } from '@client/components';

/*----------------------------------
- TYPES
----------------------------------*/

import type { Article, domains as Domain } from '@/server/models';

// With basic div attributes
type TProps = JSX.HTMLAttributes<HTMLDivElement> & {
    articles: Article[]
};

/*----------------------------------
- COMPONENT
----------------------------------*/
export default ({ articles, ...props }: TProps) => {

    return (
        <div class="grid xa4 al-fill">
            {articles.map( article => article.slug === 'dummy' ? (
                <div class="card bg silver" />
            ) : (
                <article class={"card col sp-0 pd-0"}>

                    <img src={article.thumbnail} alt={article.thumbnailAltText} style={{ 
                        borderRadius: '0.75rem 0.75rem 0 0' ,
                        flex: '0 0 15em',
                        height: '15em',
                        objectFit: 'cover'
                    }} />

                    <div class="pdv-1 pdh-2">
                        <h3 class="txt-left">
                            <Link className="super" to={article.url}>
                                {article.title}
                            </Link>
                        </h3>
                    </div>
                    
                </article>
            ))}
        </div>
    )
}