
import React, { JSX } from 'react';
import { Link } from "@client/components"

export default () => (
    <section class="Shortcuts pdv-2">

        <h2 class="txt-left">Discover the finest domains available</h2>

        <ul class="row al-left al-fill scrollable pdv-2 pageCover">
            <li>
                <Link to="/domains/artificial+intelligence" class="col sp-0 al-left txt-left card w-2">
                    <strong class="fg hologram">Artificial Intelligence</strong>
                    Domains
                </Link>
            </li>
            <li>
                <Link to="/domains?minLength=1&maxLength=5" class="col sp-0 al-left txt-left card w-2">
                    <strong class="fg hologram">Short</strong>
                    Domain Names
                </Link>
            </li>
            <li>
                <Link to="/opportunities" class="col sp-0 al-left txt-left card w-2">
                    <strong class="fg hologram">High Resell Value</strong>
                    Domains
                </Link>
            </li>
            <li>
                <Link to="/opportunities" class="col sp-0 al-left txt-left card w-2">
                    <strong class="fg hologram">Trending</strong>
                    Domain Names
                </Link>
            </li>
            <li>
                <Link to="/domains/brandable" class="col sp-0 al-left txt-left card w-2">
                    <strong class="fg hologram">Brandable</strong>
                    Domain Names
                </Link>
            </li>
        </ul>
    </section>
)